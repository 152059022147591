<template>
  <v-app color="">
    <v-app-bar color="primary" flat max-height="70" tile>
      <v-spacer></v-spacer>
      <a href="#" class="text-center pa-5">
        <img src="/media/logos/logo_light_155x30.png" class="max-h-70px" alt />
      </a>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-content class="mb-12">
      <v-container class="mb-12">
        <v-container class="mb-12">
          <v-row justify="center">
            <v-col class="text-center" cols="12" md="10">
              <template v-if="!errorMessage && $route.params.token && busy">
                <h3 class="bold-text activate-title">
                  Verifying your transfer ownership code, please wait...
                </h3>
                <content-loader />
                <span
                  class="text-muted bold-text font-size-h4 font-size-h3-lg mt-2"
                >
                  You will be automatically redirected, if not please click
                  <router-link
                    class="text-primary font-weight-bolder"
                    :to="{ name: 'team-members' }"
                    >here</router-link
                  >
                </span>
              </template>
              <template v-else-if="errorMessage">
                <vue-inline-alert
                  v-if="errorMessage"
                  :message="errorMessage"
                  message-type="error"
                  no-close-btn
                ></vue-inline-alert>
                <v-row v-if="errorMessage">
                  <v-col>
                    <v-btn
                      color="primary"
                      class="text-capitalize"
                      @click="$router.push({ name: 'dashboard' }).catch()"
                      >Go Home</v-btn
                    >
                  </v-col>
                </v-row>
              </template>
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </v-content>
    <k-t-footer></k-t-footer>
  </v-app>
</template>

<script>
import KTFooter from "@/view/layout/footer/Footer.vue";
import { VERIFY_TOKEN } from "@/store/users/auth.module";

export default {
  name: "install",
  components: { KTFooter },
  data() {
    return {
      errorMessage: false,
      busy: false,
    };
  },
  computed: {},
  created() {
    if (this.$route.params.token) {
      this.verifyCode();
    }
  },
  methods: {
    verifyCode() {
      if (this.busy) return;
      this.busy = true;
      this.$emit("error", null);
      this.errorMessage = null;
      this.$store
        .dispatch(VERIFY_TOKEN, {
          action: "transfer_ownership",
          token: `${this.$route.params.token}`,
        })
        .then(() => {
          this.busy = false;
          this.$router.push({ name: "team-members" });
        })
        .catch((err) => {
          this.$emit("error", err.message);
          this.errorMessage = err.message;
          this.busy = false;
        });
    },
  },
};
</script>

<style lang="scss">
.v-btn.install {
  width: 195px !important;
  height: 64px !important;
  border-radius: 10px !important;
  span {
    font-size: 19px !important;
    text-transform: capitalize;
    letter-spacing: 0;
  }
}
img.install-img {
  width: 195px !important;
  height: 64px !important;
}
</style>
