var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', {
    attrs: {
      "color": ""
    }
  }, [_c('v-app-bar', {
    attrs: {
      "color": "primary",
      "flat": "",
      "max-height": "70",
      "tile": ""
    }
  }, [_c('v-spacer'), _c('a', {
    staticClass: "text-center pa-5",
    attrs: {
      "href": "#"
    }
  }, [_c('img', {
    staticClass: "max-h-70px",
    attrs: {
      "src": "/media/logos/logo_light_155x30.png",
      "alt": ""
    }
  })]), _c('v-spacer')], 1), _c('v-content', {
    staticClass: "mb-12"
  }, [_c('v-container', {
    staticClass: "mb-12"
  }, [_c('v-container', {
    staticClass: "mb-12"
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12",
      "md": "10"
    }
  }, [!_vm.errorMessage && _vm.$route.params.token && _vm.busy ? [_c('h3', {
    staticClass: "bold-text activate-title"
  }, [_vm._v(" Verifying your transfer ownership code, please wait... ")]), _c('content-loader'), _c('span', {
    staticClass: "text-muted bold-text font-size-h4 font-size-h3-lg mt-2"
  }, [_vm._v(" You will be automatically redirected, if not please click "), _c('router-link', {
    staticClass: "text-primary font-weight-bolder",
    attrs: {
      "to": {
        name: 'team-members'
      }
    }
  }, [_vm._v("here")])], 1)] : _vm.errorMessage ? [_vm.errorMessage ? _c('vue-inline-alert', {
    attrs: {
      "message": _vm.errorMessage,
      "message-type": "error",
      "no-close-btn": ""
    }
  }) : _vm._e(), _vm.errorMessage ? _c('v-row', [_c('v-col', [_c('v-btn', {
    staticClass: "text-capitalize",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.$router.push({
          name: 'dashboard'
        }).catch();
      }
    }
  }, [_vm._v("Go Home")])], 1)], 1) : _vm._e()] : _vm._e()], 2)], 1)], 1)], 1)], 1), _c('k-t-footer')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }